<template>
  <div id="wrapper">
    <Header />
    <main id="main" :class="wrapperClass">
      <slot></slot>
    </main>
    <Footer />
    <ClientOnly>
      <CookieBarWrap />
    </ClientOnly>
  </div>
</template>

<script>
import Header from "@/esf_thehague_wieleroutfits/core/components/Header";
import Footer from "@/esf_thehague_wieleroutfits/core/components/Footer";
import CookieBarWrap from "@/esf_thehague_wieleroutfits/core/components/CookieBar";
import ClientOnly from "vue-client-only";

export default {
  name: "BaseLayout",
  components: {
    Header,
    Footer,
    CookieBarWrap,
    ClientOnly,
  },
  props: {
    wrapperClass: {
      type: String,
      required: false,
    },
  },
};
</script>

